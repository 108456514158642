.input__padding{
    padding:10px !important;
}

.element__mt20{
    margin-top:20px !important;
    color:red;
}

.Input__file_hidden{
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Card__root_100{
    width:100%;
}

.Typegraphy__color_blue{
    color: #3f51b5
}

.H1__profile_header{
    position: absolute;
    top: 0;
    font-size: 19px;
    left: 10px;
    background: #3f51b5;
    padding-left: 130px;
    padding-right: 39px;
    padding-top: 27px;
    text-align: center;
    width: calc(100% - 20px);
    color: #fff;
    border-radius: 8px;
    line-height: 32px;
    height: calc(100% - 22px);
    box-sizing: border-box;
}

.Avatar__file_hidden{
    width: 120px !important;
    height: 120px !important;
    border-radius: 6px !important;
    left: 5px !important;
}