.loading{
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index:999999;
}

.loadingSpinner{
    border: 3px solid rgba(0,0,0,0.03);
    border-top: 3px solid #f4fbff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    animation: spinnerIsLoading 0.8s linear infinite;
}

@keyframes spinnerIsLoading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }