.Avatar__big_image{
    width: 300px !important;
    height: 300px !important;
    position: absolute !important;
    transform: scale(0);
    transition: 0.2s;
    transform: scale(0);
    background-size: cover !important;
    top: 20px;
    border-radius: 100%;
    left: calc(50% - 150px);
}

.Avatar__big_image--active{
    width: 300px !important;
    height: 300px !important;
    position: absolute !important;
    transform: scale(0);
    transition: 0.2s;
    transform: scale(1);
    background-size: cover !important;
    top: 20px;
    border-radius: 100%;
    left: calc(50% - 150px);
}
