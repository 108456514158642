body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  padding: 0;
  color: #3b5441;
  margin: 0;
}

a{
  text-decoration: none;
}

a:active{
  color: rgb(150, 150, 218);
}

.Icon__padding_right10{
  padding-right:10px;
}

.Icon__colorblue{
  color: #3f51b5;
}